import React from 'react';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import { Doughnut, PolarArea } from 'react-chartjs-2';
import moment from 'moment';

export const getLearningObjectiveProgress = ({
  marks,
  student,
  learningObjective,
  target
}) => {
  marks = marks?.filter(m => m?.studentId == student?.id && m?.type == target);
  var data = [0, 0];

  marks?.map(m => {
    let id = m?.question?.id.split('.');
    id.pop();

    for (let i = 0; i < id.length; i++) {
      id[i] = parseInt(id[i]) + 1;
    }
    let markLearningObjectiveID = id.join('.');

    if (markLearningObjectiveID == learningObjective?.id) {
      data[0]++;
      if (m?.answer?.correct) {
        data[1]++;
      } else {
        for (const answer in m?.answer) {
          if (m?.answer[answer]?.correct) {
            data[1]++;
            break;
          }
        }
      }
    }
  });

  data[1] = Math?.ceil((data[1] / (data[0] || 1)) * 100.0);
  return data;
};

export const getCompletionRate = ({
  subjects,
  subject,
  homeworks,
  competitions,
  marks,
  filters,
  target,
  students
}) => {
  let filteredHomeworks = [];
  if (target === 'homework') {
    filteredHomeworks = homeworks;
  } else if (target === 'competition') {
    filteredHomeworks = competitions;
  }

  if (!filters?.schoolId) {
    filteredHomeworks = [];
    marks = [];
    students = [];
  }
  if (filters?.schoolId) {
    filteredHomeworks = filteredHomeworks?.filter(
      h => `${h?.schoolId}` === `${filters?.schoolId}`
    );

    if (target !== 'training') {
      marks = marks?.filter(
        m => !!filteredHomeworks?.find(h => `${h?.id}` === `${m?.entityId}`)
      );
    }
  }
  if (filters?.gradeId) {
    students =
      students?.filter(s => `${s.gradeId}` === `${filters.gradeId}`) || [];
    marks = marks?.filter(
      m => !!students?.find(u => `${u?.id}` === `${m?.studentId}`)
    );

    if (filters?.sectionId) {
      students =
        students?.filter(s => `${s.sectionId}` === `${filters?.sectionId}`) ||
        [];
      marks = marks?.filter(
        m => !!students?.find(u => `${u?.id}` === `${m?.studentId}`)
      );
    }
  }

  marks = marks.filter(mark => {
    var result = true;

    if (!!filters?.startDate && !!filters?.endDate) {
      result =
        moment(mark?.createdAt).isSameOrBefore(filters?.endDate) &&
        moment(mark?.createdAt).isSameOrAfter(filters?.startDate);
    } else if (!!filters?.startDate) {
      result = moment(mark?.createdAt).isSameOrAfter(filters?.startDate);
    } else if (!!filters?.endDate) {
      result = moment(mark?.createdAt).isSameOrBefore(filters?.endDate);
    }
    return result;
  });

  let learningObjectives = [];
  if (subject) {
    subject?.chapters?.map(c => {
      c?.topics?.map(t => {
        t?.sections?.map(s => {
          s?.LearningObjectives?.map(l => {
            learningObjectives.push(l?.id);
          });
        });
      });
    });
  } else {
    subjects?.map(s => {
      s?.chapters?.map(c => {
        c?.topics?.map(t => {
          t?.sections?.map(s => {
            s?.LearningObjectives?.map(l => {
              learningObjectives.push(l?.id);
            });
          });
        });
      });
    });
  }

  const results = [];
  learningObjectives?.map(l => {
    let numberOfSolved = 0;

    students.map(s => {
      let isSolved = false;

      marks
        .filter(m => m.studentId == s?.id)
        .map(m => {
          let id = m?.question?.id.split('.');
          id.pop();

          for (let i = 0; i < id.length; i++) {
            id[i] = parseInt(id[i]) + 1;
          }
          let markLearningObjectiveID = id.join('.');

          if (markLearningObjectiveID == l) {
            isSolved = true;
          }
        });

      if (isSolved) {
        numberOfSolved++;
      }
    });

    if (numberOfSolved === students.length && numberOfSolved !== 0) {
      results.push(1);
    } else {
      results.push(0);
    }
  });

  if (results.length == 0) {
    results.push(0);
  }

  const numberOfCorrectAnswers = results.filter(l => !!l).length;
  const numberOfWrongAnswers = results.filter(l => !l).length;

  const completedPercent = Math.ceil(
    (numberOfCorrectAnswers * 100) / (learningObjectives?.length || 1)
  );
  const notCompletedPercent = Math.floor(
    (numberOfWrongAnswers * 100) / (learningObjectives?.length || 1)
  );

  return [
    completedPercent,
    notCompletedPercent,
    numberOfCorrectAnswers,
    numberOfWrongAnswers
  ];
};

export const getClassAverage = ({
  subjects,
  subject,
  competitions,
  homeworks,
  marks,
  filters,
  target,
  students

}) => {
  // Fallback for no marks
  if (!marks || marks.length === 0) {
    console.warn("No marks available for calculating class average.");
    return [0, 100, 0, 0]; // Return 0% completed, 100% not completed
  }
  let filteredHomeworks = [];
  if (target === 'homework') {
    filteredHomeworks = homeworks;
  } else if (target === 'competition') {
    filteredHomeworks = competitions;
  }

  if (!filters?.schoolId) {
    filteredHomeworks = [];
    marks = [];
    students = [];
  }

  if (filters?.schoolId) {
    filteredHomeworks = filteredHomeworks?.filter(
      h => `${h?.schoolId}` === `${filters?.schoolId}`
    );

    if (target !== 'training') {
      marks = marks?.filter(
        m => !!filteredHomeworks?.find(h => `${h?.id}` === `${m?.entityId}`)
      );
    }
  }

  if (filters?.gradeId) {
    students =
      students?.filter(s => `${s.gradeId}` === `${filters.gradeId}`) || [];

    if (filters?.sectionId) {
      students =
        students?.filter(s => `${s.sectionId}` === `${filters?.sectionId}`) ||
        [];
    }
  }

  marks = marks?.filter(m => {
    return !!students?.find(u => `${u?.id}` === `${m?.studentId}`);
  });

  marks = marks.filter(mark => {
    var result = true;

    if (!!filters?.startDate && !!filters?.endDate) {
      result =
        moment(mark?.createdAt).isSameOrBefore(filters?.endDate) &&
        moment(mark?.createdAt).isSameOrAfter(filters?.startDate);
    } else if (!!filters?.startDate) {
      result = moment(mark?.createdAt).isSameOrAfter(filters?.startDate);
    } else if (!!filters?.endDate) {
      result = moment(mark?.createdAt).isSameOrBefore(filters?.endDate);
    }
    return result;
  });

  let questions = [];
  if (subject) {
    subject?.chapters?.map(c => {
      c?.topics?.map(t => {
        t?.sections?.map(s => {
          s?.LearningObjectives?.map(l => {
            l?.questions?.map(q => {
              questions.push(l?.id + '.' + q?.id);
            });
          });
        });
      });
    });
  } else {
    subjects?.map(s => {
      s?.chapters?.map(c => {
        c?.topics?.map(t => {
          t?.sections?.map(s => {
            s?.LearningObjectives?.map(l => {
              l?.questions?.map(q => {
                questions.push(l?.id + '.' + q?.id);
              });
            });
          });
        });
      });
    });
  }

  let results = [];
  if (subject) {
    marks
      .filter(m => {
        const subjectID = subject?.id.split('.')[1];
        const markSubjectID = parseInt(m?.question?.subjectId) + 1;
        return markSubjectID == subjectID;
      })
      ?.map(m => {
        let isAnswerCorrect = false;

        if (m?.answer?.correct) {
          isAnswerCorrect = true;
        } else {
          for (const answer in m?.answer) {
            if (m?.answer[answer]?.correct) {
              isAnswerCorrect = true;
              break;
            }
          }
        }

        let id = m?.question?.id.split('.');
        for (let i = 0; i < id.length; i++) {
          id[i] = parseInt(id[i]) + 1;
        }
        const markQuestionID = id?.join('.');

        let isCorrect = false;
        questions.find(q => {
          if (markQuestionID == q && isAnswerCorrect) {
            isCorrect = true;
          }
        });
        if (isCorrect) {
          results?.push(1);
        } else {
          results?.push(0);
        }
      });
  } else {
    for (let i = 0; i < subjects.length; i++) {
      marks
        .filter(m => {
          const subjectID = subjects[i]?.id.split('.')[1];
          const markSubjectID = parseInt(m?.question?.subjectId) + 1;
          return markSubjectID == subjectID;
        })
        ?.map(m => {
          let isAnswerCorrect = false;

          if (m?.answer?.correct) {
            isAnswerCorrect = true;
          } else {
            for (const answer in m?.answer) {
              if (m?.answer[answer]?.correct) {
                isAnswerCorrect = true;
                break;
              }
            }
          }

          let id = m?.question?.id.split('.');
          for (let i = 0; i < id.length; i++) {
            id[i] = parseInt(id[i]) + 1;
          }
          const markQuestionID = id?.join('.');

          let isCorrect = false;
          questions.find(q => {
            if (markQuestionID == q && isAnswerCorrect) {
              isCorrect = true;
            }
          });
          if (isCorrect) {
            results?.push(1);
          } else {
            results?.push(0);
          }
        });
    }
  }

  const numberOfCorrectAnswers = results?.filter(q => !!q).length;
  const numberOfWrongAnswers = results?.filter(q => !q).length;

  if (results?.length == 0) {
    results?.push(0);
  }

  const completedPercent = Math?.ceil(
    (numberOfCorrectAnswers * 100) / (results?.length || 1)
  );
  const notCompletedPercent = Math?.floor(
    (numberOfWrongAnswers * 100) / (results?.length || 1)
  );

  return [
    completedPercent,
    notCompletedPercent,
    numberOfCorrectAnswers,
    numberOfWrongAnswers,
    console.log("Marks being processed in getClassAverage:", marks),
    console.log("Filtered students:", students),
    console.log("Questions for Learning Objectives:", questions),

  ];

};

export const SubjectChart = ({
  subjects,
  subject,
  marks,
  t,
  width,
  height,
  fullpie,
  label,
  average,
  filters,
  homeworks,
  competitions,
  target,
  students,
  config,
  grades,
  curriculum
}) => {
  const options = {
    rotation: fullpie ? 0 : -90,
    circumference: fullpie ? '360' : '180',
    cutout: '80%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: average ? false : true,
        backgroundColor: !config?.isDark ? '#ffffff' : '#000000',
        borderColor: !config?.isDark ? '#ffffff' : '#000000',
        titleColor: config?.isDark ? '#ffffff' : '#000000',
        bodyColor: config?.isDark ? '#ffffff' : '#000000',
        borderWidth: 1
      }
    }
  };

  // Filter marks based on target (e.g., homework or competition)
  if (target) {
    marks = marks.filter(m => m.type === target);
  }

  let d = [0, 0, 0, 0]; // Fallback data structure

  if (grades) {
    let tempData = [];
    for (let i = 0; i < grades.length; i++) {
      const grade = grades[i];
      const gradeStudents = students?.filter(s => s?.gradeId == grade?.id);
      const gradeSubjects = curriculum?.find(
        subject => `${subject.id}` === `${grade?.level}`
      )?.subjects;

      // Debug logs for verification
      console.log(`Processing Grade: ${grade?.name}`);
      console.log('Grade Students:', gradeStudents);
      console.log('Grade Subjects:', gradeSubjects);

      tempData = average
        ? getClassAverage({
          subjects: gradeSubjects,
          competitions,
          marks,
          homeworks,
          filters,
          target,
          students: gradeStudents
        })
        : getCompletionRate({
          subjects: gradeSubjects,
          competitions,
          marks,
          homeworks,
          filters,
          target,
          students: gradeStudents
        });

      d[0] += tempData[0];
      d[1] += tempData[1];
      d[2] += tempData[2];
      d[3] += tempData[3];
    }
  } else {
    d =
      !subject && !subjects
        ? ['0', '100'] // Fallback for empty subjects
        : average
          ? getClassAverage({
            subjects,
            subject,
            competitions,
            marks,
            homeworks,
            filters,
            target,
            students
          })
          : getCompletionRate({
            subjects,
            subject,
            competitions,
            marks,
            homeworks,
            filters,
            target,
            students
          });
  }

  // Handle fallback for empty marks or no data
  if (d.every(val => val === 0)) {
    console.warn(`No data available for subject: ${subject?.name || 'Unknown'}`);
    d = [0, 100, 0, 0]; // Default fallback to 0% completed
  }

  const data = {
    labels: [
      average
        ? t('home:chart.totalCompletedQuestions')
        : t('home:chart.completedLearningObjectives'),
      average
        ? t('home:chart.totalNotCompletedQuestions')
        : t('home:chart.NotCompletedLearningObjectives')
    ],
    datasets: [
      {
        data: [d[2], !d[3] && !d[2] ? 1 : d[3]],
        backgroundColor: [
          getColor('warning'),
          config?.isDark ? '#ffffff' : 'grey'
        ],
        borderColor: [getColor('warning'), config?.isDark ? '#ffffff' : 'grey'],
        hoverBackgroundColor: [
          getColor('warning'),
          config?.isDark ? '#ffffff' : 'grey'
        ]
      }
    ]
  };

  let plugins = [];
  if (label) {
    plugins = [
      {
        beforeDraw: function (chart) {
          var circumference = chart.config?._config.options.circumference;
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 160).toFixed(2);
          ctx.font = fontSize + 'em sans-serif';
          ctx.textBaseline = 'top';
          ctx.direction = 'ltr';
          ctx.fillStyle = config?.isDark ? '#ffffff' : '#000000';
          var text = `${grades ? Math.ceil((d[2] / (d[2] + d[3])) * 100) : d[0]
            }%`,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / (circumference == 360 ? 2.2 : 1.5);
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }
    ];
  }

  return (
    <div style={{ width, height, display: 'flex' }}>
      <Doughnut data={data} options={options} plugins={plugins} redraw />
    </div>
  );
};


export const PolarChart = ({
  marks,
  students,
  homeworks,
  filters,
  target,
  t,
  width,
  height,
  competitions,
  grades
}) => {
  let filteredHomeworks = [];

  if (target === 'homework') {
    filteredHomeworks = homeworks;
  } else if (target === 'competition') {
    filteredHomeworks = competitions;
  }

  if (!filters?.schoolId) {
    filteredHomeworks = [];
    marks = [];
    students = [];
  }
  if (filters?.schoolId) {
    filteredHomeworks = filteredHomeworks?.filter(
      h => `${h?.schoolId}` === `${filters?.schoolId}`
    );

    if (target !== 'training') {
      marks = marks?.filter(
        m => !!filteredHomeworks?.find(h => `${h?.id}` === `${m?.entityId}`)
      );
    }
  }
  if (filters?.gradeId) {
    students =
      students?.filter(s => `${s.gradeId}` === `${filters.gradeId}`) || [];
    marks = marks?.filter(
      m => !!students?.find(u => `${u?.id}` === `${m?.studentId}`)
    );

    if (filters?.sectionId) {
      students =
        students?.filter(s => `${s.sectionId}` === `${filters?.sectionId}`) ||
        [];
      marks = marks?.filter(
        m => !!students?.find(u => `${u?.id}` === `${m?.studentId}`)
      );
    }
  }

  if (target) {
    marks = marks.filter(m => m.type?.toLowerCase() === target);
  }

  if (grades) {
    students = students?.filter(s => {
      let isInGrades = false;
      for (let i = 0; i < grades.length; i++) {
        if (s?.gradeId == grades[i]?.id) {
          isInGrades = true;
          break;
        }
      }

      return isInGrades;
    });
  }

  let finalData = [0, 0, 0, 0, 0];
  const groups = [
    { from: 0, to: 50 },
    { from: 51, to: 69 },
    { from: 70, to: 79 },
    { from: 80, to: 89 },
    { from: 90, to: 100 }
  ];

  students?.map(s => {
    const studentResult = marks.filter(m => m.studentId === s?.id);

    groups.map((g, i) => {
      const percentage =
        studentResult.length != 0
          ? (studentResult.filter(r => !!r).length / studentResult.length) * 100
          : 0;
      if (percentage >= g?.from && percentage <= g?.to) {
        finalData[i] += 1;
      }
    });
  });

  let colors = [
    rgbaColor(getColor('danger'), 0.5),
    rgbaColor(getColor('warning'), 0.5),
    rgbaColor(getColor('info'), 0.5),
    rgbaColor(getColor('primary'), 0.5),
    rgbaColor(getColor('success'), 0.5)
  ];

  let labels = [
    t('home:chart.polar1'),
    t('home:chart.polar2'),
    t('home:chart.polar3'),
    t('home:chart.polar4'),
    t('home:chart.polar5')
  ];

  colors = colors.filter((c, i) => !!finalData[i]);
  labels = labels.filter((l, i) => !!finalData[i]);
  finalData = finalData.filter(d => !!d);

  const data = {
    datasets: [
      {
        data: finalData,
        backgroundColor: colors,
        borderWidth: 1,
        borderColor: getColor('gray-400')
      }
    ],
    labels,
    borderColor: getColor('gray-400') //- not working
  };

  const options = {
    plugins: {
      tooltip: chartJsDefaultTooltip(),
      legend: {
        position: 'bottom'
      }
    },
    maintainAspectRatio: false,
    scales: {
      r: {
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    }
  };

  return (
    <PolarArea data={data} options={options} height={height} width={width} />
  );
};
